import React from 'react';
import CheckoutSignInPhonePage from '../../component/checkout/checkout-sign-in-phone-page';
import CheckoutSignInCodePage from '../../component/checkout/checkout-sign-in-code-page';
import CheckoutSignInPasswordPage, { signIn_param } from '../../component/checkout/checkout-sign-in-password-page';
import CheckoutCompleteCustomerInfo from '../../component/checkout/checkout-complete-customer-info';
import { showApiMessage } from '../../messenger/messageManager';
import apiManage from '../../request';
import Notification from '../../component/snackbar/snackbar';
import i18n from '../../i18n';
import moment from 'moment';
import { LocalStorageManager } from '../../constants/tool-local-storage';
import { LOCAL_STORAGE_KEYS } from '../../constants/local-storage.constant';
import { errorCatching } from '../../tool/error-catching';
import { Customer, CustomerProjectionFixed } from '../../models';
import _ from 'lodash';
import { DefaultWebsocketClient } from '../../modules/websocket/module';
import { getBrowserPhoneCode } from '../../constants/tool-get-browser-language';
import './sign-in.scss';
import { createHashHistory } from 'history';
import Store from '../../rudex/store';
import ForgetPassword from './forget-password';
import CheckoutThirdPartySignInPhone from '../../component/checkout/checkout-third-party-signIn-phone';
import { getMessages } from '../../tool/notices.tool';
import { ToAuthentication, checkAccountVerify } from '../../constants/checkRoute';
import { checkToken, cleanAccountCache } from '../../constants/tool-check-token';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';

export enum ICheckoutShowedPageNames {
    'SIGN_IN_INPUT_PHONE_PAGE' = 'SIGN_IN_INPUT_PHONE_PAGE',
    'SIGN_IN_INPUT_VCODE_PAGE' = 'SIGN_IN_INPUT_VCODE_PAGE',
    'SIGN_IN_INPUT_PASSWORD_PAGE' = 'SIGN_IN_INPUT_PASSWORD_PAGE',
    'COMPLETE_CUSTOMER_INFO_PAGE' = 'COMPLETE_CUSTOMER_INFO_PAGE',
    'ORDER_DETAIL_PAGE' = 'ORDER_DETAIL_PAGE',
    'FORGET_PASSWORD' = 'FORGET_PASSWORD',
    'THIRD_PARTY_SIGN_IN_PHONE' = 'THIRD_PARTY_SIGN_IN_PHONE'
}

export const TimeoutAtCodeDelay = 90;
export default class SignPage extends React.Component<Props, State> {
    public state: State = {
        /**
         * 当前显示的子页面
         * SIGN_IN_INPUT_PHONE_PAGE - 登入页面 - 输入手机号
         * SIGN_IN_INPUT_VCODE_PAGE - 登入页面 - 输入验证码
         * SIGN_IN_INPUT_PASSWORD_PAGE - 登入页面 - 输入密码
         * COMPLETE_CUSTOMER_INFO_PAGE - 信息补全页面
         * FORGET_PASSWORD - 忘记密码
         */
        showedPage: ICheckoutShowedPageNames.SIGN_IN_INPUT_PHONE_PAGE,
        /**
         * 验证码倒计时相关
         */
        // 验证码倒计时时间（秒）
        vcodeTimeMS: TimeoutAtCodeDelay,
        /**
         * 手机号码验证相关
         */
        phonePrefix: getBrowserPhoneCode(),
        phone: '',
        // 缺失
        isFirstName: false,
        isLastName: false,
        isAddress: false,
        isPassword: false,

        // 是否跳转忘记密码页面
        isShowForgetPassword: false,
        thirdPartyToken: '',
        thirdPartyType: undefined,

        // whatsapp 再次获取验证码倒计时
        whatsappVcodeTimeMS: TimeoutAtCodeDelay,
        referralsCode: null,

        isVisibleAccount: false,
        isReferralsCode: false
    }

    // *********************
    // Life Cycle Function
    // *********************


    // *********************
    // Default Function
    // *********************

    // 进入sign-in页面检查是否有推荐码
    checkEnterOfReferralCodeFromURL() {
        const regular = /(referrals=[0-9a-zA-Z]{5})/gi;
        const Url = window.location.href;
        const r = Url.match(regular);
        if (r != null) {
            return unescape(r[0]).split('=')[1];
        }
        return null
    }

    componentDidMount(): void {
        // 检查是否是推荐分享的地址
        const referralsCode = this.checkEnterOfReferralCodeFromURL();
        this.setState({
            referralsCode
        });


        // 检查token是否失效
        setTimeout(() => {
            const isTokenInvalid = checkToken();
            if (isTokenInvalid) {
                Notification.notification(i18n.t('general_text_Not_logged_in_for_a_long_time_need_to_log_in_again'), { autoHideDuration: 5000 });
            }
        }, 1000);

        const ACCOUNT = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT);
        let customerId = '';
        if (ACCOUNT) { customerId = ACCOUNT._id }

        // 如果没有账号id，也不需要请求数据了
        if (!customerId) {
            cleanAccountCache()
            return
        }

        // 有账号id，请求数据
        if (referralsCode) {
            apiManage.get_customer_info(customerId, undefined, CustomerProjectionFixed(['pr_key_info', 'pr_messages_info', 'pr_basic_info'])).then((customerInfo) => {
                // 错误捕捉
                if ((customerInfo as any).error) return;
                // 获取通知信息
                getMessages(_.get(customerInfo, 'messages', 0));
                // 把个人信息的存在存到缓存中
                if (customerInfo.my_recommendation_code === referralsCode) {
                    Notification.notification(i18n.t('account_page_text_same_as_the_current_user_invitation_code'))
                    createHashHistory().push('/')
                } else if (customerInfo.last_name || customerInfo.first_name || customerInfo.address) {
                    // 老用户不再允许修改推荐码
                    // 邀请码仅适用于新用户
                    Notification.notification(i18n.t('account_page_text_The_invitation_code_is_only_applicable_to_new_users'))
                    this.setState({
                        referralsCode: null
                    })
                    createHashHistory().push('/')
                }
            });
        }

    }

    updateState(update: Partial<State>): void {
        // tslint:disable-next-line: no-any
        this.setState(update as any);
    }

    /**
     * 切换页面
     * @param targetPage
     */
    public updateShowedPage(targetPage: any) {
        if (targetPage === ICheckoutShowedPageNames.ORDER_DETAIL_PAGE) {
            if (this.state.vcodeTimeoutObject) {
                clearInterval(this.state.vcodeTimeoutObject)
            }
        }
        this.setState({
            showedPage: targetPage
        })
    }

    /**
     * 获取验证码
     */
    public async getVerificationCode(toWhatsapp = false) {
        const { phonePrefix, phone, isShowForgetPassword } = this.state;
        let type = isShowForgetPassword ? 'RESET_PASSWORD_BY_PHONE' : 'INIT_BY_PHONE';
        if (toWhatsapp) {
            type = 'INIT_BY_WHATS'
        }
        const postData = {
            account_type: 'CUSTOMER',
            type,
            phone_prefix: phonePrefix,
            phone
        }
        try {
            // 发送重置密码的验证码前  先检查号码是否存在
            if (isShowForgetPassword) {
                // 判断号码是否存在
                const isPhoneExistResult = await apiManage.isPhoneExist({
                    phone_prefix: phonePrefix,
                    phone
                });
                // 账号不存在
                if (!isPhoneExistResult) {
                    Notification.notification(i18n.t('reset_password_page_text_account_does_not_exist'));
                    return;
                }
                // 账号存在 跳转忘记密码页面
                else {
                    this.setState({
                        showedPage: ICheckoutShowedPageNames.FORGET_PASSWORD
                    })
                }
            }
            //  请求验证码
            const RESULT_VCODE = await apiManage.post_admin_code(postData);
            // const RESULT_VCODE: any = { postData, whatsResult: { messages: [{ id: 0 }] } };
            if (toWhatsapp) {
                if (RESULT_VCODE?.whatsResult?.messages?.[0]?.id !== undefined || !RESULT_VCODE.error) {
                    // 提示发送成功
                    Notification.notification(i18n.t('account_page_text_send_success'));
                    // whatsapp记录验证码发送时间加90秒
                    Store.dispatch({
                        type: 'SET_STATE',
                        path: 'sendWhatsappVcodeEndTimeInit',
                        value: moment().add(TimeoutAtCodeDelay, 'second').format('YYYY-MM-DD HH:mm:ss')
                    })
                    this.startWhatsappTiming();
                } else {
                    // 提示发送失败
                    console.log('提示发送失败', RESULT_VCODE.error);
                    showApiMessage(RESULT_VCODE, 'vcode');
                    const sendVcodeEndTimeInit = _.get(Store.getState(), 'sendWhatsappVcodeEndTimeInit');
                    // 发送验证码结束时间 - 现在时间
                    const currentTime = moment().format('YYYY-MM-DD HH:mm:ss');
                    let countdownValue = moment(sendVcodeEndTimeInit).diff(currentTime, 'seconds');
                    countdownValue = clamp(countdownValue, 0, TimeoutAtCodeDelay);
                    this.setState({
                        whatsappVcodeTimeMS: countdownValue
                    }, () => {
                        if (countdownValue <= 0) {
                            this.checkoutClearWhatsappInterval();
                        } else {
                            this.startWhatsappTiming();
                        }
                    })
                }
            } else {
                const path = isShowForgetPassword ? 'sendVcodeEndTimePassword' : 'sendVcodeEndTimeInit';
                // 请求失败
                if (RESULT_VCODE.error) {
                    // 提示获取验证码失败
                    showApiMessage(RESULT_VCODE, 'vcode');
                    // 倒计时
                    const sendVcodeEndTime = _.get(Store.getState(), path);
                    // 发送验证码结束时间 - 现在时间
                    const currentTime = moment().format('YYYY-MM-DD HH:mm:ss');
                    let countdownValue = moment(sendVcodeEndTime).diff(currentTime, 'seconds');
                    countdownValue = clamp(countdownValue, 0, TimeoutAtCodeDelay);
                    this.setState({
                        vcodeTimeMS: countdownValue
                    }, () => {
                        if (countdownValue <= 0) {
                            this.checkoutClearInterval();
                        } else {
                            this.startTiming();
                        }
                    })
                }
                else {
                    // 记录验证码发送时间加TimeoutAtCodeDelay秒
                    Store.dispatch({
                        type: 'SET_STATE',
                        path,
                        value: moment().add(TimeoutAtCodeDelay, 'second').format('YYYY-MM-DD HH:mm:ss')
                    })
                    this.startTiming();
                }
            }
        } catch (error) {
            //
        }
    }

    async loginByThirdPartyApi(params: { third_party_token: string; third_party_type: 'GOOGLE' | 'FACEBOOK'; }) {
        const res_signIn: any = await apiManage.loginByThirdParty(params);
        this.loginByThirdParty(res_signIn, params);
    }
    /**
     * 判断第三方登录
     * - 第三方账号，在本应用没有账号,则会创建账号,可以没有手机号码;
     */
    loginByThirdParty(res_signIn, params) {
        if (_.get(res_signIn, 'error') && _.get(res_signIn, 'result.statusCode') === 403 && _.get(res_signIn, 'result.code') === 1051) {
            // 第三方账号，在本应用没有账号
            // this.setState({
            //     showedPage: ICheckoutShowedPageNames.THIRD_PARTY_SIGN_IN_PHONE
            // })
            // return
        } else if (_.get(res_signIn, 'error')) {
            Notification.notification(i18n.t('request_failure_text_operation_failed'));
            return
        }
        // 用户可能缺少的字段([ 'first_name', 'last_name', 'phone','phone_prefix', 'password', 'is_password_page'])
        const shortage = _.get(res_signIn, 'shortage', []);
        if (_.includes(shortage, 'phone') || _.includes(shortage, 'phone_prefix')) {
            // 在本应用有账号但是没有手机，每次都进入完善信息页
            // this.setState({
            //     showedPage: ICheckoutShowedPageNames.THIRD_PARTY_SIGN_IN_PHONE
            // })
            // return
        }
        // 保存缓存第三方token
        LocalStorageManager.setLocalStorage({
            path: LOCAL_STORAGE_KEYS.THIRD_PARTY_TOKEN,
            value: {
                third_party_token: params.third_party_token,
                third_party_type: params.third_party_type
            }
        });

        // 已有账号检查缺失
        this.CheckCustomerInfoMissing(res_signIn, _.includes(shortage, 'password'));
    }

    /**
     * 获取顾客信息，检验顾客是否缺失信息
     */
    public async CheckCustomerInfoMissing(res_signIn, password?) {
        try {
            if (res_signIn.error) {
                showApiMessage(res_signIn, 'customerInit');
            } else {
                const param = {
                    access_token: _.get(res_signIn, 'accessToken'),
                    expiresInDate: moment(new Date()).add(res_signIn.expiresIn, 's').format()
                }

                LocalStorageManager.setLocalStorage({
                    path: LOCAL_STORAGE_KEYS.TOKEN,
                    value: param
                });

                const customerInfo = await apiManage.get_customer_info(_.get(res_signIn, '_id', ''), undefined, CustomerProjectionFixed(['pr_key_info', 'pr_messages_info', 'pr_basic_info']));
                // 获取通知信息
                getMessages(_.get(customerInfo, 'messages', 0));
                if (!customerInfo) {
                    showApiMessage(customerInfo, 'customerInit');
                    return;
                }
                // 判断之前用户阅读协议
                if (!customerInfo.protocol_status) {
                    await apiManage.patch_customer_info({ protocol_status: true }, _.get(res_signIn, '_id', ''));
                }
                // // 获取用户头像图片地址
                const getAvatar = await apiManage.get_staff_id_avatar(res_signIn._id) || ''
                // 错误捕捉
                if (errorCatching(getAvatar, 'account_page_text_request_avatar_failed')) return;
                _.set(customerInfo, 'avatar', getAvatar)
                const customer: Customer = {
                    _id: customerInfo._id,
                    nickname: customerInfo.nickname,
                    phone: customerInfo.phone,
                    phone_prefix: customerInfo.phone_prefix,
                    email: customerInfo.email,
                    first_name: customerInfo.first_name,
                    last_name: customerInfo.last_name,
                    avatar: _.get(customerInfo, 'avatar'),
                    // 是否是测试账号
                    is_virtual: customerInfo.is_virtual
                }
                // 存储账号信息
                LocalStorageManager.setLocalStorage({
                    path: LOCAL_STORAGE_KEYS.ACCOUNT,
                    value: customer
                });
                // 断开连接socket.io
                DefaultWebsocketClient.disconnect();
                // 添加socket.io连接
                apiManage.websocket_init();
                // 判断信息是否缺失
                if (!customerInfo.address || !customerInfo.first_name || !customerInfo.last_name || !customerInfo.phone) {
                    // 保存账号信息
                    const isReferralsCode = !(customerInfo.first_name || customerInfo.last_name || customerInfo.address);

                    if (isReferralsCode) {
                        Store.dispatch({
                            type: 'SET_STATE',
                            path: 'checkLoginInfo',
                            value: {
                                first_name: res_signIn.first_name,
                                last_name: res_signIn.last_name
                            }
                        });
                    }


                    this.setState({
                        showedPage: ICheckoutShowedPageNames.COMPLETE_CUSTOMER_INFO_PAGE,
                        isFirstName: customerInfo.first_name ? false : true,
                        isLastName: customerInfo.last_name ? false : true,
                        isAddress: customerInfo.address ? false : true,
                        isPassword: password ? true : (_.indexOf(_.get(res_signIn, 'shortage', []), 'is_password_page') !== -1),
                        isVisibleAccount: !customerInfo.phone,
                        isReferralsCode
                    })
                } else {
                    if (this.state.vcodeTimeoutObject) {
                        clearInterval(this.state.vcodeTimeoutObject)
                    }

                    // 如果是绑定的账号，解绑后，重新用旧的手机号和旧的第三方登录，信息没有缺失，重新调用绑定的方法
                    if (this.state.thirdPartyType !== undefined && this.state.thirdPartyToken) {
                        const params = {
                            third_party: {
                                type: this.state.thirdPartyType?.toLocaleUpperCase(),
                                token: this.state.thirdPartyToken
                            }
                        }
                        await apiManage.patch_customer_info(params, customerInfo._id || '')
                    }

                    this.goHistory();
                }
            }
        } catch (error) {
            //
        }
    }

    /**
     * 登录接口
     */
    public async signIn(params: signIn_param) {
        try {
            if (params.type === 'password') {
                delete params.type
            }
            /**
             * 获取账号信息和token
             */
            const res_signIn = await apiManage.post_customer_init(params);
            console.log(res_signIn);
            // 判断第三方账号登入
            if (params.third_party_token && params.third_party_type) {
                this.loginByThirdParty(res_signIn, params)
            } else {
                const shortage = _.get(res_signIn, 'shortage', []);
                // 手机登入
                this.CheckCustomerInfoMissing(res_signIn, _.includes(shortage, 'password'));
            }
        } catch (error) {
            //
        }
    }

    /**
     * 跳转到之前的页面
     */
    public async goHistory(isHomePage?: boolean, clearShowTipCallBackAction?: boolean) {
        // shark会员审核
        const verifyId = Store.getState().verifyId;
        if (verifyId) {
            ToAuthentication(verifyId);
            return;
        } else {
            const isReplaceRout = await checkAccountVerify();
            if (isReplaceRout) return;
        }

        const currentResStringID = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.CURRENT_RES_STRING_ID);
        // 跳转到之前的页面
        if (createHashHistory().length <= 2 || isHomePage) {
            const merchantWebsite = _.get(Store.getState(), 'merchantWebsite');
            if (merchantWebsite) {
                createHashHistory().push(`/restaurantPage?string_id=${currentResStringID}&merchant_website=true`)
            } else {
                if (currentResStringID) {
                    createHashHistory().push(`/restaurantPage?string_id=${currentResStringID}`)
                } else {
                    createHashHistory().push(`/`)
                }
            }
        } else {
            if (clearShowTipCallBackAction) {
                Store.dispatch({
                    type: 'SET_STATE',
                    path: 'showTipCallBackAction',
                    value: undefined
                })
            }
            createHashHistory().goBack();
            // 解决路由跳转问题
            const t1 = setTimeout(() => {
                const pathName = createHashHistory().location.pathname
                if (pathName === '/sign-in') {
                    this?.goHistory(true);
                }
                clearTimeout(t1)
            }, 200);

        }
    }


    /**
     * 开启倒计时
     */
    public startTiming() {
        this.checkoutClearInterval();
        this.setState({
            vcodeTimeoutObject: setInterval(
                () => {
                    this.setState({
                        vcodeTimeMS: this.state.vcodeTimeMS - 1
                    })
                    if (this.state.vcodeTimeMS <= 0) {
                        this.checkoutClearInterval();
                    }
                },
                1000
            ),
            vcodeTimeMS: this.state.vcodeTimeMS - 1
        });
    }

    /**
     * 开启whatsapp倒计时
     */
    public startWhatsappTiming() {
        this.checkoutClearWhatsappInterval();
        this.setState({
            whatsappVcodeTimeoutObject: setInterval(
                () => {
                    this.setState({
                        whatsappVcodeTimeMS: this.state.whatsappVcodeTimeMS - 1
                    })
                    if (this.state.whatsappVcodeTimeMS <= 0) {
                        this.checkoutClearWhatsappInterval();
                    }
                },
                1000
            ),
            whatsappVcodeTimeMS: this.state.whatsappVcodeTimeMS - 1
        });
    }

    /**
     * 清定时器
     */
    public checkoutClearInterval() {
        if (this.state.vcodeTimeoutObject) {
            clearInterval(this.state.vcodeTimeoutObject)
        }
        this.setState({ vcodeTimeMS: TimeoutAtCodeDelay });
    }

    /**
     * 清除whatsapp定时器
     */
    public checkoutClearWhatsappInterval() {
        if (this.state.whatsappVcodeTimeoutObject) {
            clearInterval(this.state.whatsappVcodeTimeoutObject)
        }
        this.setState({ whatsappVcodeTimeMS: TimeoutAtCodeDelay });
    }

    // *********************
    // View
    // *********************

    render() {
        const { showedPage, phonePrefix, phone, vcodeTimeMS, isFirstName, isAddress, isLastName, isPassword, isShowForgetPassword } = this.state;
        const showedPageView = {
            [ICheckoutShowedPageNames.SIGN_IN_INPUT_PHONE_PAGE]:
                <CheckoutSignInPhonePage
                    isShowBack={true}
                    phonePrefix={phonePrefix}
                    phone={phone}
                    closeCheckoutComponent={() => isShowForgetPassword ? this.setState({ isShowForgetPassword: false }) : this.goHistory(false, true)}
                    updateShowedPage={(targetPage) => this.updateShowedPage(targetPage)}
                    updateState={(state: Pick<State, 'phone' | 'phonePrefix'>) => this.updateState(state)}
                    sendPhoneGetVerificationCode={async (toWhatsapp?: boolean) => {
                        await this.getVerificationCode(toWhatsapp)
                    }}
                    isShowForgetPassword={isShowForgetPassword}
                    checkoutClearInterval={() => { this.checkoutClearInterval(); }}
                    jumpToPerfectInfo={(type, token) => {
                        this.setState({
                            thirdPartyToken: token,
                            thirdPartyType: type,

                        }, async () => {
                            const params = { third_party_token: token, third_party_type: type! };
                            await this.loginByThirdPartyApi(params);
                        })
                    }}
                    clickForgetPassword={() => {
                        this.setState({
                            isShowForgetPassword: true,
                            showedPage: ICheckoutShowedPageNames.SIGN_IN_INPUT_PHONE_PAGE
                        })
                    }}
                    signInPhoneWithPassword={async (param: signIn_param) => { await this.signIn(param) }}
                />,
            [ICheckoutShowedPageNames.SIGN_IN_INPUT_VCODE_PAGE]:
                <CheckoutSignInCodePage
                    phonePrefix={phonePrefix}
                    phone={phone}
                    closeCheckoutComponent={() => { }}
                    updateShowedPage={(targetPage) => this.updateShowedPage(targetPage)}
                    updateState={() => { }}
                    sendPhoneGetVerificationCode={async (toWhatsapp?: boolean) => {
                        await this.getVerificationCode(toWhatsapp);
                    }}
                    vcodeTimeMS={vcodeTimeMS}
                    whatsappVcodeTimeMS={this.state.whatsappVcodeTimeMS}
                    signIn={async (param: signIn_param) => {
                        const isWhatsappLoginByCodeReviced = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.VERIFY_CODE_PENDING_SOURCE)
                        await this.signIn({ ...param, type: isWhatsappLoginByCodeReviced && isWhatsappLoginByCodeReviced === 'WHATSAPP' ? 'INIT_BY_WHATS' : 'INIT_BY_PHONE' })
                    }}
                    jumpToPerfectInfo={(type, token) => {
                        this.setState({
                            thirdPartyToken: token,
                            thirdPartyType: type
                        }, async () => {
                            const params = { third_party_token: token, third_party_type: type };
                            this.signIn(params)
                        })
                    }}
                />,
            [ICheckoutShowedPageNames.SIGN_IN_INPUT_PASSWORD_PAGE]:
                <CheckoutSignInPasswordPage
                    phonePrefix={phonePrefix}
                    phone={phone}
                    closeCheckoutComponent={() => { }}
                    updateShowedPage={(targetPage) =>
                        this.updateShowedPage(targetPage)
                    }
                    updateState={() => { }}
                    signIn={(param: signIn_param) => { this.signIn(param) }}
                    isShowForgetPassword={true}
                    clickForgetPassword={() => {
                        this.setState({
                            isShowForgetPassword: true,
                            showedPage: ICheckoutShowedPageNames.SIGN_IN_INPUT_PHONE_PAGE
                        })
                    }}
                />,
            [ICheckoutShowedPageNames.COMPLETE_CUSTOMER_INFO_PAGE]:
                <CheckoutCompleteCustomerInfo
                    // 显示姓名补全
                    isVisibleCustomerName={isFirstName && isLastName}
                    // 显示地址补全
                    isVisibleAddress={isAddress}
                    isOrderway={true}
                    isVisiblePassword={isPassword}
                    closeCheckoutComponent={() => { this.goHistory() }}
                    updateShowedPage={() => this.goHistory()}
                    updateState={(obj: any) => { this.updateState(obj) }}
                    thirdPartyToken={this.state.thirdPartyToken}
                    thirdPartyType={this.state.thirdPartyType}
                    referralsCode={this.state.referralsCode}
                    isReferralsCode={this.state.isReferralsCode}
                    isVisibleAccount={this.state.isVisibleAccount}
                />,

            [ICheckoutShowedPageNames.FORGET_PASSWORD]:
                <ForgetPassword
                    phonePrefix={phonePrefix}
                    phone={phone}
                    vcodeTimeMS={vcodeTimeMS}
                    closeCheckoutComponent={() => { }}
                    updateShowedPage={(targetPage) => this.updateShowedPage(targetPage)}
                    updateState={() => { }}
                    sendPhoneGetVerificationCode={() => { this.getVerificationCode() }}
                    signIn={(param: signIn_param) => { this.signIn(param) }}
                />,

            [ICheckoutShowedPageNames.THIRD_PARTY_SIGN_IN_PHONE]:
                <CheckoutThirdPartySignInPhone
                    checkoutClearInterval={() => {
                        //
                    }}
                    updateState={(state: any) => this.updateState(state)}
                    // 发送验证码
                    sendPhoneGetVerificationCode={() => {
                        this.getVerificationCode()
                    }}
                    // 跳转页面
                    updateShowedPage={(targetPage) => this.updateShowedPage(targetPage)}
                    // 第三方手机号密码
                    phone={phone}
                    phonePrefix={phonePrefix}
                    vcodeTimeMS={vcodeTimeMS}
                    signIn={(param: signIn_param) => {
                        // 跳转信息补全页
                        this.signIn(param)
                    }}
                />
        };
        return (
            <div className='sign-in-page'>
                <div className='checkoutDrawerMain'>
                    {
                        showedPageView[showedPage]
                    }
                </div>
            </div>
        )
    }
}

// *********************
// Props & State
// *********************

type State = {
    /**
     * 当前显示的子页面
     * SIGN_IN_INPUT_PHONE_PAGE - 登入页面 - 输入手机号
     * SIGN_IN_INPUT_VCODE_PAGE - 登入页面 - 输入验证码
     * SIGN_IN_INPUT_PASSWORD_PAGE - 登入页面 - 输入密码
     * COMPLETE_CUSTOMER_INFO_PAGE - 信息补全页面
     * FORGET_PASSWORD - 忘记密码
     */
    showedPage: ICheckoutShowedPageNames;
    /**
     * 验证码倒计时相关
     */
    // 验证码倒计时时间（秒）
    vcodeTimeMS: number;
    vcodeTimeoutObject?: NodeJS.Timeout;
    /**
     * 手机号码验证相关
     */
    phonePrefix: string;
    phone: string;
    /**
     * 信息补全相关
     */
    isFirstName: boolean
    isLastName: boolean;
    isAddress: boolean;
    isPassword: boolean;

    // 是否跳转忘记密码页面
    isShowForgetPassword: boolean;

    thirdPartyToken: string;
    thirdPartyType: 'GOOGLE' | 'FACEBOOK' | undefined;

    whatsappVcodeTimeMS: number
    whatsappVcodeTimeoutObject?: NodeJS.Timeout;
    referralsCode: string | null;
    isVisibleAccount: boolean;
    isReferralsCode: boolean;
}
type Props = {}



function clamp(value, min, max) {
    return Math.min(Math.max(value, min), max);
}
/**
 * 谷歌验证
 */
export const FeatGoogleReCaptcha = React.memo(
    function ReCaptcha({ showGoogleReCaptcha, onVerify }: { showGoogleReCaptcha: boolean, onVerify: (token: string) => void }) {
        return showGoogleReCaptcha ? <GoogleReCaptcha onVerify={onVerify} /> : null
    }, (pre, next) => {
        return pre.showGoogleReCaptcha === next.showGoogleReCaptcha
    }
)