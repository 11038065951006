import { Address, Vcode, RecordObject, CustomerOrder, Schedule, Certificate, IntegralRecord, Integral, Coupon } from '.';

export class Customer {

    // *********************
    // Key Info
    // *********************

    _id?: string;

    nickname?: string;

    phone?: string;

    email?: string;

    // *********************
    // Basic Info
    // *********************

    first_name?: string;

    last_name?: string;

    protocol_status?: boolean;
    /**
     * 0：male 男
     * 1：female 女
     * 2：others 其他
     */
    gender?: string;

    avatar?: {
        name: string
        type: string
        version: string
        _id: string
    };

    birth?: Date;

    address?: Array<Address>;

    phone_prefix?: string;

    // 账号偏向语言
    locale?: string;

    // *********************
    // Auth
    // *********************

    password?: string;

    vcode?: Vcode;

    is_verified?: boolean;

    // *********************
    // Record Info
    // *********************
    collect_restaurants?: Array<
        {
            city: string;
            country: string;
            description: string;
            email: string;
            label: Array<string>
            local: string;
            logo: string;
            nickname: string;
            postcode: string;
            setting_app_takeaway_service_schedule: Array<Schedule>;
            setting_app_takeaway_service_status: boolean;
            state: string;
            status: true
            string_id: string;
            tel: string;
            tel_prefix: string;
            website: string;
            _id: string;
        }
    >;
    restaurant_string_ids?: Array<
        {
            city: string;
            country: string;
            description: string;
            email: string;
            label: Array<string>
            local: string;
            logo: string;
            nickname: string;
            postcode: string;
            setting_app_takeaway_service_schedule: Array<Schedule>;
            setting_app_takeaway_service_status: boolean;
            state: string;
            status: true
            string_id: string;
            tel: string;
            tel_prefix: string;
            website: string;
            _id: string;
        }
    >;

    records?: Array<RecordObject>;

    // *********************
    // Order Info
    // *********************

    orders?: Array<CustomerOrder>;

    // *********************
    // Third-party Related Info
    // *********************

    // google
    google_related_id?: string;

    google_related_email?: string;

    google_related_status?: boolean;

    // facebook
    facebook_related_id?: string;

    facebook_related_email?: string;

    facebook_related_status?: boolean;

    // 是否是第一次设置密码
    first_setting?: boolean;

    // 第三方登录token
    clientToken?: string;

    // 第三方登陆类型
    clientType?: string;

    // 是否是测试账号
    is_virtual?: boolean;

    // 积分
    integrals?: Array<Integral>;

    // 积分记录
    integral_record?: Array<IntegralRecord>;

    // 我的优惠券
    coupons?: Coupon[];

    // 是否为该集团黑名单
    is_blocked_user?: boolean;

    // 黑名单备注
    detail?: string;

    // 被设置为黑名单的时间
    blocked_date?: Date;

    // 餐厅会员
    restaurant_member_ids?: string[];

    // 用户本身推荐码
    my_recommendation_code?: string
    // 用户上级推荐人
    my_superior_reference_recommendation_code?: string
}

/**
 * 根据条件查找多个Restaurant资源 参数类型
 */
export type CustomerConditionParams = {
    nickname?: string;
    phone?: string;
    email?: string;
    first_name?: string;
    last_name?: string;

    /**
     * 0：male
     * 1：female
     * 2：others
     */

    gender?: string;
    // projection
    pr_key_info?: boolean;
    pr_basic_info?: boolean;
    pr_record?: boolean;
    pr_third_party_related_info?: boolean;
    // pagination
    pag_per_page: number;
    pag_page: number;
    // sort
    sort_key?: string;
    sort_value?: -1 | 1;
}

// 令牌元信息
export type TokenMetadata = {
    // 账户类型
    account_type?: string;
    // 头像
    avatar?: {
        name: string
        type: string
        version: string
        _id: string
    };
    // 绑定餐厅（string_id）
    belonged_restaurant?: Array<string>
    // 证书
    certificate?: Array<Certificate>
    // 邮箱
    email?: string;
    exp?: number;
    first_name?: string;
    gender?: string;
    iat?: number;
    is_verified?: boolean;
    last_name?: string;
    nickname?: string;
    phone?: string;
    responsible_restaurant?: Array<string>;
    _id?: string;
    // Third-party Related Info
    google_related_id?: string,
    google_related_email?: string,
    google_related_status?: boolean,
    facebook_related_id?: string,
    facebook_related_email?: string,
    facebook_related_status?: boolean
}
type CustomerProjectionType = { [key: string]: any };

/**
 * 根据传入的 showKeys 生成一个投影对象。
 * @param showKeys - 需要显示的键数组
 * @returns 生成的投影对象
 */
export function CustomerProjectionFixed(showKeys: (keyof typeof CustomerProjection)[]): CustomerProjectionType {
    if (!Array.isArray(showKeys)) {
        // throw new Error('showKeys must be an array');
    }

    if (Object.keys(CustomerProjection).length === 0) {
        // throw new Error('CustomerProjection is empty');
    }

    if (showKeys.length === 0) {
        return {} as CustomerProjectionType;
    }

    const project: CustomerProjectionType = {};
    const showKeysSet = new Set(showKeys);

    // 遍历 CustomerProjection 的所有键
    Object.keys(CustomerProjection).forEach((key: any) => {
        // 如果 key 在 showKeys 中，则设置为 true，否则设置为 false
        project[key] = showKeysSet.has(key);
    });

    return project;
}

export const CustomerProjection = {
    pr_key_info: {
        _id: true,
        nickname: true,
        phone: true,
        email: true,
        is_virtual: true,
        is_pre_creation: true
    },
    pr_basic_info: {
        protocol_status: true,
        phone_prefix: true,
        first_name: true,
        last_name: true,
        avatar: true,
        gender: true,
        birth: true,
        address: true,
        locale: true,
        is_password_page: true,
        collect_restaurants: true,
        language: true
    },
    pr_auth: {
        password: true,
        vcodes: true,
        is_verified: true
    },
    pr_record: {
        restaurant_string_ids: true,
        records: true,
        sign_in_record: true
    },
    pr_coupon: {
        coupons: true,
        integrals: true,
        integral_record: true,
        orders: true
    },
    pr_third_party_related_info: {
        google_related_id: true,
        google_related_email: true,
        google_related_status: true,
        facebook_related_id: true,
        facebook_related_email: true,
        facebook_related_status: true,
        apple_related_id: true,
        apple_related_email: true,
        apple_related_status: true,
    },
    pr_integral_info: {
        integrals: true,
        integral_record: true,
        accumulative: true
    },
    pr_booking_info: {
        booking_orders: true
    },
    pr_messages_info: {
        messages: true
    },
    pr_member: {
        my_recommendation_code: true,
        my_superior_reference_recommendation_code: true,
        my_recommendation_integrals: true
    }
};