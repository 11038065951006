import React from 'react';
import { Clear, Check } from '@material-ui/icons';
import { IS_VCODE } from '../../constants/base.constant';
import i18n from '../../i18n';
import { Button } from '@material-ui/core';

// 校验验证码
export default function VerificationCode(props: {
    defaultValue: string;
    onChange: (val: string) => void;
    isCountDown?: boolean;
    onChangeSecond?: () => void;
    codeSecond?: number;
}) {
    // const IS_VCODE = /^\d{6}$/;
    const [isCode, setIsCode] = React.useState(true);
    return (
        <div className='code-main code-input-main'>
            <div className='code-content'>
                <div className='pos-relative'>
                    <input
                        className='verification-code-insert-in'
                        value={props.defaultValue}
                        type='tel'
                        pattern='[0-9]'
                        maxLength={6}
                        onChange={(val: any) => {
                            const EXP = /^[0-9]*$/;
                            if (EXP.test(val.target.value)) {
                                if (typeof props.onChange === 'function') props.onChange(val.target.value);
                                setIsCode(IS_VCODE.test(val.target.value))
                            }
                        }}
                        placeholder={i18n.t('reset_password_page_text_insert_vcode')}
                    />
                    {
                        props.defaultValue.length > 0
                            ? (
                                isCode
                                    ? <Check className='checkoutCheckIcon' />
                                    : <Clear className='checkoutClearIcon' />
                            )
                            : <Check className='space' />
                    }
                    {/* 验证码倒计时 */}
                    <div className='code-timing'>
                        <Button className='send-code'
                            disabled={props.isCountDown}
                            onClick={() => {
                                if (!props.isCountDown) {
                                    props.onChangeSecond?.()
                                }
                            }}>
                            {
                                props.isCountDown ? `${i18n.t('signup_page_button_resend', { 1: props.codeSecond })}` : `${i18n.t('account_page_text_send')}`
                            }
                        </Button>
                    </div>
                </div>
            </div>
        </div>


    )
}