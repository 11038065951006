import * as React from 'react';
import _ from 'lodash';
import TopBar from '../../component/top-bar/top-bar.component';
import { Button, Grid } from '@material-ui/core';
import './delivery-address.component.scss'
import RoomIcon from '@material-ui/icons/Room';
import FaceIcon from '@material-ui/icons/Face';
import RingVolumeIcon from '@material-ui/icons/RingVolume';
import AddIcon from '@material-ui/icons/Add';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { Customer, Address, CustomerProjectionFixed } from '../../models';
import apiManage from '../../request';
import { CreateAddress } from '../payment/payment.componet';
import i18n from '../../i18n';
import { RestaurantTheme } from '../../constants/tool-restaurant-theme';
import Notification from '../../component/snackbar/snackbar';
import { checkAddress } from '../../constants/tool-googlemap';
import { LocalStorageManager } from '../../constants/tool-local-storage';
import { LOCAL_STORAGE_KEYS } from '../../constants/local-storage.constant';
import { CachedAddressDeliveryFee } from '../../constants/tool-check-level-delivery';
import { getMessages } from '../../tool/notices.tool';

// 修改地址
class DeliveryAddress extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            language: 'EN',
            addressCardInfo: [],
            isEdiAddressModal: false,
            editAddress: {
                street: 'Enter street name with number',
                homeNumber: 'Enter your home number',
                city: '',
                zipCode: '',
                details: 'Describe details about the address'
            },
            // 修改地址数据
            modiftAddress: {},
            // 是否有选择地址
            isChoose: false
        }
    }

    // ***************
    //  生命周期
    // ***************
    componentDidMount() {
        const { customerInfo, type } = this.props;
        const addressCardInfo = customerInfo.address || [];
        addressCardInfo.sort(this.compare)


        if (type === 'scanCodePage' || type === 'customerInfo') {
            this.setState({
                addressCardInfo,
                isChoose: true
            })
        } else {
            // 判断地址是否在配送范围
            this.isWithinRange(addressCardInfo);
        }
    }

    // ***************
    // 方法区
    // ***************

    /**
     * 判断地址是否在配送范围内
     * @param address 所有地址
     */

    public async isWithinRange(address: Array<Address>) {
        const res_string_id = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.CURRENT_RES_STRING_ID);
        const res = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.RESTAURANTS);
        const res_info = _.get(res, `[${res_string_id}].restaurant`);
        const allAdress = await checkAddress(res_info, address);

        allAdress.forEach(async (item,) => {
            if (item.default) {
                if (item.isWithin) {
                    await CachedAddressDeliveryFee(item);
                } else {
                    item.default = false
                }
            }
        });
        this.isChoosed(allAdress);
        this.setState({
            addressCardInfo: allAdress
        })
    }

    /**
     * 判断按钮高亮
     * @param addressCardInfo 地址集合
     */
    public isChoosed(addressCardInfo: Array<Address>) {
        // 确认按钮是否灰掉
        const isChoose = addressCardInfo.findIndex((item) => {
            return item.default === true
        })
        this.setState({ isChoose: isChoose === -1 ? false : true })
    }

    public isSelected(itemAddress: any, index) {
        const setCardInfo = _.cloneDeep(this.state.addressCardInfo);

        if (this.props.type === 'scanCodePage' || this.props.type === 'customerInfo') {
            setCardInfo.forEach((item, idx) => {
                if (index === idx) {
                    item.default = true;
                } else {
                    item.default = false
                }
            });
            this.setState({
                addressCardInfo: setCardInfo
            })
        } else {
            let isChoose = false;
            setCardInfo.forEach(async (item, idx) => {
                if (index === idx) {
                    item.default = true;
                    isChoose = true;
                    await CachedAddressDeliveryFee(item)
                } else {
                    item.default = false
                }
            });
            this.setState({
                addressCardInfo: setCardInfo,
                isChoose
            })
        }
    }

    // 修改用户信息
    public async patchCustomerInfo() {
        try {
            const { customerInfo } = this.props;
            const cacheAccount: Customer = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT) || {};
            const customerId = cacheAccount._id || '';
            let addressCardInfo = _.cloneDeep(this.state.addressCardInfo);
            // 去掉帮助性字段
            addressCardInfo = addressCardInfo.map((n) => {
                n.isWithin = undefined;
                n.line = undefined;
                return n;
            })
            _.set(customerInfo, `address`, addressCardInfo)
            // 此接口，是修改整个用户信息列表
            await apiManage.patch_customer_info(
                { address: customerInfo.address },
                customerId
            )
        } catch (error) { }
    }

    // 请求获取到用户的所有地址
    public async getCustomerAddress(id: string) {
        try {
            const customerInfo = await apiManage.get_customer_info(id, undefined, CustomerProjectionFixed(['pr_basic_info', 'pr_messages_info']));

            const addressCardInfo = customerInfo !== null ? customerInfo.address : [];
            // 所有地址需要排序,要把default放在最上面

            if (addressCardInfo) addressCardInfo.sort(this.compare);

            if (customerInfo._id) {
                if (this.props.type === 'scanCodePage' || this.props.type === 'customerInfo') {
                    this.setState({
                        addressCardInfo: addressCardInfo || []
                    })
                    this.props.changeStatus(customerInfo, true);
                } else {
                    this.props.changeStatus(customerInfo, true);
                    this.isWithinRange(addressCardInfo || []);
                }
                // 获取通知信息
                getMessages(_.get(customerInfo, 'messages', 0));
            } else {
                Notification.notification(i18n.t('delivery_address_page_get_address_info_failed'))
            }
        } catch (err) {
            console.log(err)
        }
    }

    // 数组对象排序处理方法
    public compare(preAddress: Address) {
        if (preAddress.default) return -1;
        return 1;
    }

    updateState = (value: any) => {
        this.setState(value)
    }
    // ***************
    // VIEW
    // ***************

    public render() {
        const { addressCardInfo, isEdiAddressModal, modiftAddress, isChoose } = this.state;
        const { customerInfo, type, restaurantTheme } = this.props;
        const title = type === 'restaurant' ? `delivery_address_page_Confirm_address` : 'delivery_address_page_text_client_my_delivery_address'
        return (
            <div className='defaultThemeAddress'>
                <div className='delivery-address-main overall-center'>
                    {/* Top bar */}
                    <TopBar
                        backgroundstyle={'backgroundTransparent'}
                        isHideMenu={type === 'restaurant' ? false : true}
                        title={i18n.t(title)}
                        isGoBack={() => {
                            if (type === 'restaurant' || type === 'scanCodePage') {
                                if (this.props.isGoBack) {
                                    this.props.isGoBack()
                                }
                            } else {
                                this.props.changeStatus({ isShowAddress: false })
                            }
                        }}
                    />
                    {/* 新建地址 */}
                    {
                        isEdiAddressModal ? <CreateAddress
                            addressList={addressCardInfo}
                            customerInfo={customerInfo}
                            modiftAddress={modiftAddress}
                            handleClose={() => {
                                this.updateState({ isEdiAddressModal: false });
                            }}
                            refreshAddress={() => { // 重新做一次请求
                                this.setState({ isEdiAddressModal: false }, async () => {
                                    this.getCustomerAddress(customerInfo._id || '');
                                })
                            }}
                            restaurantTheme={restaurantTheme}
                            restaurantPosition={this.props.restaurantPosition}
                            handleChoose={() => { this.setState({ isEdiAddressModal: false }) }}
                        /> : null
                    }
                    <div className='delivery-address-content'>
                        <div className='main-body'>
                            <div className='delivery-address-list'>
                                <div className='address-main-bg boxShadow'>
                                    <div className='flex-center-between'>
                                        <div className='address-tit'>
                                            {i18n.t('delivery_address_page_text_client_add_address')}
                                        </div>
                                        <div onClick={(e) => {
                                            e.stopPropagation();
                                            this.setState({
                                                modiftAddress: {},
                                                isEdiAddressModal: true
                                            })
                                        }}><AddIcon style={{ color: '#9791BF' }} /></div>
                                    </div>
                                    <div className='address-msg flex-center-start'>
                                        {
                                            i18n.t('delivery_address_page_text_client_choose_your_default_address')
                                        }
                                    </div>
                                    <div className='flex-center-between-column margin-top'>
                                        {
                                            addressCardInfo && addressCardInfo.map((item, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className='address-card boxShadow-card'
                                                        style={item.default ? {
                                                            backgroundColor: '#323161'
                                                        } : {}}
                                                        onClick={() => {
                                                            if (!item.isWithin && type !== 'scanCodePage' && type !== 'customerInfo') return;
                                                            this.isSelected(item, index)
                                                        }}>
                                                        <Grid container wrap='nowrap' alignItems={'flex-start'} >
                                                            <Grid item xs={2}>
                                                                <RoomIcon style={{ color: '#ffdc33' }}></RoomIcon>
                                                            </Grid>
                                                            <Grid item xs={8}>
                                                                {/* 城市 */}
                                                                <div className='delivery-font-style-BC textAlign'>
                                                                    {`${item.country}`}
                                                                </div>
                                                                {/* 地址 */}
                                                                <div className='delivery-font-style-6C textAlign'
                                                                    style={item.default ? { color: '#fff' } : {}} >
                                                                    {/* {`${item.street} ${item.home_number} ${item.code} ${item.city} ${item.state} ${item.country}`} */}
                                                                    {`${item.street} ${item.home_number}`}
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <div className='icon' onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    this.setState({
                                                                        modiftAddress: { ...item, index },
                                                                        isEdiAddressModal: true
                                                                    })
                                                                }}>
                                                                    <KeyboardArrowRightIcon style={item.default ? { color: '#5867B4' } : {}} />
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container wrap='nowrap' alignItems={'center'} >
                                                            <Grid item xs={2}>
                                                                <FaceIcon style={{ color: '#989EB6' }}></FaceIcon>
                                                            </Grid>
                                                            <Grid item xs={8}>
                                                                <div className='delivery-font-style-BC' style={{ textAlign: 'left' }}>{`${item.first_name || ''} ${item.last_name || ''}`}</div>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid container wrap='nowrap' alignItems={'center'} >
                                                            <Grid item xs={2}>
                                                                <RingVolumeIcon style={{ color: '#989EB6' }} />
                                                            </Grid>
                                                            <Grid item xs={8}>
                                                                <div className='delivery-font-style-BC' style={{ textAlign: 'left' }}>{`${item.phone || ''}`}</div>
                                                            </Grid>
                                                        </Grid>
                                                        {
                                                            !item.isWithin && type !== 'scanCodePage' && type !== 'customerInfo' && <Grid container wrap='nowrap' alignItems={'center'} >
                                                                <Grid item xs={10}>
                                                                    <div className='delivery-font-style-BC' style={{ textAlign: 'left', color: 'red', paddingLeft: 20 }}>{i18n.t('payment_page_text_this_address_is_not_within_the_scope_of_shipping')}</div>
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            <Button className={`${isChoose ? 'confirm' : 'notChoose'} boxShadow-btn`} onClick={async () => {

                                const customerID = customerInfo._id
                                const checkAddressCard: any = _.find(addressCardInfo, (val) => {
                                    return val.default === true
                                }) || {};
                                if (Object.values(checkAddressCard).length) {
                                    const { google_map_lat, google_map_lng } = checkAddressCard
                                    if (!(google_map_lat && google_map_lng)) {
                                        // 请重新定位谷歌地址地址
                                        Notification.notification(i18n.t('payment_page_text_the_address_selected_needs_to_be_repositioned'));
                                        return
                                    }
                                    if (checkAddressCard && customerID) {
                                        const selectedAddress = checkAddressCard as Address;
                                        await this.patchCustomerInfo();
                                        if (type === 'scanCodePage') {
                                            this.props.changeStatus({ address: addressCardInfo, _id: customerID });
                                        } else if (type === 'restaurant') {
                                            this.props.changeStatus({});
                                        } else {
                                            this.props.changeStatus({
                                                addressModifiedInfo: {
                                                    country: selectedAddress.city,
                                                    address: `${selectedAddress.street} ${selectedAddress.code} ${selectedAddress.city} ${selectedAddress.state} ${selectedAddress.country}`,
                                                    username: `${selectedAddress.first_name} ${selectedAddress.last_name}`,
                                                    tel: selectedAddress.phone
                                                }
                                            });
                                            this.props.changeStatus({
                                                isShowAddress: false,
                                                isSelectedAddress: true
                                            });
                                        }
                                    }
                                } else {
                                    // 请选择默认地址
                                    Notification.notification(i18n.t('delivery_address_page_text_client_choose_your_default_address'));
                                }
                            }}>{i18n.t('delivery_address_page_text_address_confirmed')}</Button>
                        </div>
                    </div>
                </div >
            </div>

        )
    }
}

export default DeliveryAddress;

// **********
// Type && State
// **********

type Props = {
    changeStatus: (val, status?: boolean) => void;
    customerInfo: Customer;
    type?: string;
    isGoBack?: () => void;
    restaurantTheme: RestaurantTheme;
    restaurantPosition?: { lat: number, lng: number };
};

type State = {
    language: string;
    addressCardInfo: Array<Address>;
    isEdiAddressModal: boolean;
    editAddress: {
        street: string;
        homeNumber: string;
        city: string;
        zipCode: string;
        details: string;
    }
    modiftAddress: Address,
    // 是否有选择地址
    isChoose: boolean;
}

