import React from 'react';
import { Typography } from '@material-ui/core';
import ButtonComponent from '../button.component';
import './scss/checkout-sign-in-phone.scss';
import { ICheckoutShowedPageNames, ICheckoutSignInInputPassword, ICheckoutSignInInputPasswordProps, ICheckoutSignInInputPasswordState, ICheckoutState } from '../../interfaces';
import PasswordInput from '../password-input.component';
import { MdChevronLeft } from 'react-icons/md';
import { IS_PASSWORD, PropType } from '../../constants/base.constant';
import i18n from '../../i18n';

class SignInPasswordPage extends React.Component<Props, State> implements ICheckoutSignInInputPassword {
    public state: State = {
        password: ''
    }

    // *********************
    // Life Cycle Function
    // *********************

    // *********************
    // Default Function
    // *********************

    /**
     * #### 密码登录api 获取顾客头像和顾客信息,
     * 判断顾客信息是否完善, 不完善跳入信息补全页面
     */
    public async signIn() {
        const { phone, phonePrefix } = this.props;
        const params = {
            phone,
            phone_prefix: phonePrefix,
            password: this.state.password
        }

        this.props.signIn(params);
    }

    // *********************
    // View
    // *********************
    render() {
        const { password } = this.state;
        const { isShowForgetPassword } = this.props;
        return (
            <div className='checkout-sign-in-phone'>
                <div className='headBox'>
                    <span className='actionIcon' onClick={() => this.props.updateShowedPage(ICheckoutShowedPageNames.SIGN_IN_INPUT_PHONE_PAGE)}>
                        <MdChevronLeft />
                    </span>
                    <span className='title'>
                        {i18n.t('signin_page_text_password_login')}
                    </span>
                </div >
                <PasswordInput
                    className='sign-up-password'
                    defaultValue={password}
                    isSame={true}
                    onChange={(val: string) => {
                        this.setState({
                            password: val
                        });
                    }}
                    placeholder={i18n.t('delivery_address_page_please_enter_your_password')}
                    showTip={false}
                />
                {
                    isShowForgetPassword &&
                    <Typography className='change-button' onClick={this.props.clickForgetPassword}>
                        {i18n.t('signin_page_text_forget_password')}
                    </Typography>
                }

                <ButtonComponent
                    className={!IS_PASSWORD.test(password) ? 'signup-button-not-phone' : 'signup-button-div'}
                    value={i18n.t('payment_page_text_next')}
                    onClick={() => {
                        if (!IS_PASSWORD.test(password)) return;

                        this.signIn()
                    }}
                />
            </div>
        )
    }
}

export default SignInPasswordPage


// *********************
// Props & State
// *********************

interface State extends ICheckoutSignInInputPasswordState {

}

interface Props extends ICheckoutSignInInputPasswordProps {
    // 文本框 - 国家区号
    phonePrefix: PropType<ICheckoutState, 'phonePrefix'>;
    // 文本框 - 手机号
    phone: PropType<ICheckoutState, 'phone'>;
    // 登录接口
    signIn: (param: signIn_param) => void;
    // 是否显示忘记密码 默认不显示
    isShowForgetPassword?: boolean;
    // 点击忘记密码
    clickForgetPassword?: () => void;
}

export type signIn_param = {
    phone?: string;
    phone_prefix?: string;
    code_value?: string;
    password?: string;
    third_party_token?: string;
    third_party_type?: string;
    type?: 'INIT_BY_PHONE' | 'INIT_BY_WHATS' | 'password'
}


