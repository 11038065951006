import { Button, Dialog } from '@material-ui/core'
import React from 'react'
import i18n from '../../i18n'

export function VCodeDialog({ show, confirm, vcodeType, onClose }: {
    show: boolean,
    confirm: () => void,
    vcodeType: 'SMS' | 'WhatsApp',
    onClose: () => void
}) {
    const reRenderText = () => {
        const text = i18n.t('login_page_We_will_send_you_a_x_message_for_verification', { x: vcodeType })
        const index = text.indexOf(vcodeType);
        return (
            <>
                {text.slice(0, index)}
                <span style={{ color: '#5E6899' }}>{vcodeType}</span>
                {text.slice(index + vcodeType.length)}
            </>
        )
    }
    return <Dialog
        open={show}
        onClose={() => {
            onClose?.()
        }}
        className={`v-code-dialog`}
    >
        <div className='v-code-dialog-wrapper'>
            <div className='v-code-dialog-content'>
                {reRenderText()}
            </div>
            <div className='v-code-dialog-footer'>
                <Button className='btn' onClick={() => { onClose() }}>{i18n.t('general_text_cancel')}</Button>
                <Button className='btn confirm' onClick={() => { confirm() }}>{i18n.t('general_text_ok')}</Button>
            </div>
        </div>
    </Dialog>
}