import moment from 'moment';
import _ from 'lodash';
import { LOCAL_STORAGE_KEYS } from './local-storage.constant';
import { LocalStorageManager } from './tool-local-storage';
import { DefaultWebsocketClient } from '../modules/websocket/module';
import Store from '../rudex/store';
import apiManage from '../request';
import { CustomerProjectionFixed } from '../models';

/**
 * 检查token是否有效
 */
export function checkToken() {
    let isTokenInvalid = false;
    const token = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.TOKEN);
    try {
        if (_.get(token, 'access_token')) {
            // 现在时间是否在token失效时间之后
            if (token.expiresInDate && moment(new Date()).isAfter(token.expiresInDate)) {
                cleanAccountCache();
                isTokenInvalid = true;
            } else {
                // console.log('token有效');
            }
        }
    } catch (error) { }
    return isTokenInvalid;
}


/**
 * 移除账户缓存
 * - ACCOUNT 账户信息
 * - TOKEN 登入token
 * - CHECKOUT_DATA 结算托盘数据
 * - 断开Websocket连接
 */
export function cleanAccountCache(removeShopCart?: boolean) {
    try {
        Store.dispatch({
            type: 'SET_STATE',
            path: 'hasNewNotices',
            value: false
        })
        localStorage.removeItem(LOCAL_STORAGE_KEYS.ACCOUNT);
        localStorage.removeItem(LOCAL_STORAGE_KEYS.TOKEN);
        localStorage.removeItem(LOCAL_STORAGE_KEYS.CHECKOUT_DATA);
        localStorage.removeItem(LOCAL_STORAGE_KEYS.VERIFY_CODE_PENDING_SOURCE);
        localStorage.removeItem(LOCAL_STORAGE_KEYS.THIRD_PARTY_TOKEN);
        if (removeShopCart) {
            localStorage.removeItem(LOCAL_STORAGE_KEYS.SHOP_CAR);
        }
        DefaultWebsocketClient.disconnect();
    } catch (error) { }
}


/**
 * 第三方账号绑定账号，获取当前用户信息、token
 */
export async function getBindAccountUserInfo() {
    try {
        const thirdPartyToken = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.THIRD_PARTY_TOKEN);
        if (thirdPartyToken) {
            const res: any = await apiManage.loginByThirdParty(thirdPartyToken);
            const param = {
                access_token: _.get(res, 'accessToken'),
                expiresInDate: moment(new Date()).add(res.expiresIn, 's').format()
            }
            LocalStorageManager.setLocalStorage({
                path: LOCAL_STORAGE_KEYS.TOKEN,
                value: param
            });
            const mergeCustomerInfo = await apiManage.get_customer_info(res._id, undefined, CustomerProjectionFixed(['pr_key_info', 'pr_basic_info']));
            // // 获取用户头像图片地址
            const getAvatar = await apiManage.get_staff_id_avatar(res._id) || ''
            _.set(mergeCustomerInfo, 'avatar', getAvatar)

            return mergeCustomerInfo || {};
        } else {
            return {};
        }
    } catch (error) {
        return {}
    }
}